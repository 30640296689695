<template>
    <article class="objetivo" :open="open" :canopen="data.indicadores != 0">

        <div class="row-between" @click="show">
            <h3>{{ data.carta }}</h3>
            <!-- <div class="info-complete">
                <i v-if="data.fechaevaluacion">{{data.fechaevaluacion | year}}</i>
                <span class="mini-progress-bar">
                    <span class="progress" :style="`width:${data.compromisoscumplimiento}%`" :title="`Porcentaje completado:${data.compromisoscumplimiento}%`"></span>
                </span>
            </div> -->
        </div>


        <template v-if="open">

            <div class="datos-carta -section">

                <article>
                    <h2>Fecha aprobación</h2>
                    <p v-if="data.fechaaprobacion">{{ data.fechaaprobacion | parsedate }}</p>
                </article>
                <article>
                    <h2>Última certificación</h2>
                    <p v-if="data.fechaultimacertificacion">{{ data.fechaultimacertificacion | parsedate }}</p>
                </article>
                <article>
                    <h2>Última evaluación</h2>
                    <p v-if="data.fechaevaluacion">{{ data.fechaevaluacion | parsedate }}</p>
                </article>
                <article>
                    <h2>Área de<br>Acción</h2>
                    <p>{{ data.areadeaccion }}</p>
                </article>
                <article>
                    <h2>Área de Gobierno</h2>
                    <p>{{ data.areagobierno }}</p>
                </article>
                <article>
                    <h2>Unidad Responsable</h2>
                    <p>{{ data.unidadresponsable }}</p>
                </article>
                <article>
                    <h2>Compromisos asociados</h2>
                    <p class="center">
                        <span class="compromisos">
                            {{ data.compromisos }}
                        </span>
                    </p>
                </article>

            </div>

            <div class="contenedor-boton">
                <!-- <router-link v-if="!checkroutepublico()" :to="$t('enlaces.listadocartasservicios') + '/' + data.cartaid"
                    class="btn">Consultar carta
                </router-link>
                <router-link v-else :to="$t('enlaces.listadocartasserviciospublico') + '/' + data.cartaid" class="btn">
                    Consultar carta
                </router-link> -->

                <router-link v-if="!checkroutepublico()"
                    :to="`${$t('enlaces.listadocartasservicios')}/${normalizeNombreCarta(data.carta)}`" class="btn">
                    Consultar carta
                </router-link>
                <router-link v-else
                    :to="`${$t('enlaces.listadocartasserviciospublico')}/${normalizeNombreCarta(data.carta)}`"
                    class="btn">
                    Consultar carta
                </router-link>

            </div>

        </template>
    </article>
</template>

<script>

import moment from 'moment';
import { normalizeNombreCarta } from '@/utils/utils';


export default {
    name: 'carta',
    props: ['data'],
    data: () => ({
        open: false
    }),
    methods: {
        show() {
            if (this.data.indicadores != 0) {
                this.open = !this.open;
            }
        },
        checkroutepublico() {
            if (this.$route.path.includes('publico')) {
                return true;
            } else {
                return false;
            }
        },
        normalizeNombreCarta,
    },
    filters: {
        parsedate(value) {
            moment.locale('es');
            return moment(value).format('L');

        },
        year(value) {
            moment.locale('es');
            return moment(value).format('YYYY');
        }
    }
}
</script>


<style lang="scss" scoped>
.contenedor-estrategias {
    padding: 20px;
    background-color: white;

    .tabla-estrategia {
        margin-top: 0;

        .tabla {
            margin-top: 0;
        }
    }
}

.objetivo {
    >div {
        padding-right: 60px;
        cursor: pointer;
    }
}

.objetivo h3 {
    width: calc(100% - 180px);
    padding-right: 10px;
}

.info-complete {
    width: 100%;
    max-width: 180px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    i {
        font-weight: bold;
        margin-right: 10px;
        font-size: 14px;
    }
}

@media screen and (max-width:800px) {

    .objetivo {
        padding-right: 30px;
        padding-bottom: 10px;
    }

    .objetivo>div {
        padding-right: 0px;
        margin-bottom: 10px;

        h3 {
            width: 100%;
            padding-bottom: 10px;
            padding-top: 10px;
            font-size: 15px;
        }
    }

    .info-complete {
        justify-content: flex-start;
    }

    .objetivo .datos-carta h2 {
        min-height: 30px;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .objetivo .datos-carta p {    margin-bottom: 14px;
    }
}
</style>