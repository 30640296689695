<template>
    <div class="wrapper">

        <section class="encabezado -section" data="areas-gobierno">

            <h2 class="title -white">Áreas de Gobierno, Distritos y Pleno</h2>

            <h3 class="subtitle">
                ¿Quién es el responsable?
            </h3>

        </section>

        <nav class="breadcrumb -section">
            <div class="container row-start">
                <router-link to="/">Inicio</router-link>
                <router-link :to="$route.fullPath">Áreas de Gobierno, Distritos y Pleno</router-link>
            </div>
        </nav>

        <a @click="back" class="back">Volver</a>

        <div class="container row-center contenedor-areas">

            <div class="contenedor-input -full" v-if="dominios.ejes">

                <multiselect v-model="areaseleccionada" :options="dominios.areas_gobierno"
                    placeholder="Áreas de Gobierno, Distritos y Pleno" label="nombre" :tagPosition="'bottom'"
                    :showLabels="false" :showNoResults="false" track-by="nombre">
                </multiselect>

            </div>
        </div>

        <nav class="selector-areas-accion">
            <div class="container row-center" v-if="dominios.areas_gobierno">
                <a :active="areaseleccionada.id == area.id" @click="areaseleccionada = area" class="area-municipal"
                    :key="key" v-for="(area, key) in dominios.areas_gobierno" :title="area.nombre"
                    :style="`order:${area.orden}`">{{ area.nombre }}</a>
            </div>
        </nav>

        <section class="contenedor-tip-objetivo row-center ais -section">
            <div class="container row-center">

                <div class="left">
                    <h2>{{ areaseleccionada.nombre }}</h2>
                    <p v-if="areaseleccionada.descripcion" v-html="areaseleccionada.descripcion"></p>
                    <!-- <progress-bar :title="'Actuaciones terminadas'" :percent="areaseleccionada.actuacionesterminadas*100/areaseleccionada.actuaciones" :color="'green'"> 
                                                                                                                                                                                                                            </progress-bar>-->
                    <progress-bar :title="'Actuaciones terminadas y en ejecución'" :percent="terminadasyenejecucion"
                        :color="'green'">
                    </progress-bar>
                    <count-down :title="'Tiempo restante del mandato'" :percent="homedata.tiempo_restante"
                        :color="'yellow'">
                    </count-down>
                </div>

                <div class="right">


                    <nav class="categories row-start">
                        <a @click="type = 'indicadores'" :active="type == 'indicadores'"
                            class="btn -light">Indicadores</a>
                        <a @click="type = 'actuaciones'" :active="type == 'actuaciones'"
                            class="btn -light">Actuaciones</a>
                        <a @click="type = 'cartas'" :active="type == 'cartas'" class="btn -light">Cartas de
                            servicios</a>
                    </nav>

                    <div class="contenedor-datos-graficas row-center">

                        <!-- Opción actuaciones  -->
                        <template v-if="areaseleccionada && type == 'actuaciones'">

                            <grafica-percent v-if="areaseleccionada" :total="areaseleccionada.actuaciones"
                                :label="'Planificadas'" :terminadas="areaseleccionada.actuacionesterminadas"
                                :ejecucion="areaseleccionada.actuacionesiniciadas" :planificadas="noiniciadas">
                            </grafica-percent>


                            <div class="stats row-center">
                                <article class="stat -small -terminadas">
                                    <strong>
                                        <count-to :endVal="areaseleccionada.actuacionesterminadas"
                                            :separator="'.'"></count-to>
                                    </strong>
                                    <span>Terminadas</span>
                                </article>

                                <article class="stat -small -ejecucion">
                                    <strong>
                                        <count-to :endVal="areaseleccionada.actuacionesiniciadas"
                                            :separator="'.'"></count-to>
                                    </strong>
                                    <span>En ejecución</span>
                                </article>

                                <article class="stat -small -no-iniciadas">
                                    <strong>
                                        <count-to :endVal="noiniciadas" :separator="'.'"></count-to>
                                    </strong>
                                    <span>No iniciadas</span>
                                </article>

                            </div>

                        </template>

                        <!-- Opción indicadores -->
                        <template v-if="areaseleccionada && type == 'indicadores'">

                            <!-- <grafica-percent v-if="areaseleccionada" :total="areaseleccionada.indicadores"
                            :label="'Total'" :terminadas="areaseleccionada.indicadores"
                            :ejecucion="areaseleccionada.indicadores" :planificadas="areaseleccionada.indicadores">
                            </grafica-percent> -->

                            <div class="stats row-center">
                                <!-- Estratégicos -->
                                <article class="stat -small -terminadas">
                                    <strong>
                                        <count-to :endVal="areaseleccionada.indicadorespe" :separator="'.'"></count-to>
                                    </strong>
                                    <span>Estratégicos</span>
                                </article>

                                <!-- De acción -->
                                <article class="stat -small -ejecucion">
                                    <strong>
                                        <count-to :endVal="areaseleccionada.indicadoresaccion"
                                            :separator="'.'"></count-to>
                                    </strong>
                                    <span>De acción</span>
                                </article>

                                <!-- Sectoriales -->
                                <article class="stat -small -sectoriales">
                                    <strong>
                                        <count-to :endVal="areaseleccionada.indicadoressectoriales"
                                            :separator="'.'"></count-to>
                                    </strong>
                                    <span>Sectoriales</span>
                                </article>

                            </div>
                        </template>

                        <!-- Opción cartas -->
                        <template v-if="areaseleccionada && type == 'cartas'">

                            <!-- <grafica-percent v-if="areaseleccionada" :total="areaseleccionada.cartas"
                            :label="'Total'" :terminadas="areaseleccionada.cartas"
                            :ejecucion="areaseleccionada.cartas" :planificadas="areaseleccionada.cartas">
                            </grafica-percent> -->

                            <div class="stats row-center">
                                <article class="stat -small -terminadas">
                                    <strong>
                                        <count-to :endVal="cartas(areaseleccionada.id).length"
                                            :separator="'.'"></count-to>
                                    </strong>
                                    <span>Cartas de servicios</span>
                                </article>

                            </div>
                        </template>


                    </div>

                </div>

            </div>

        </section>

        <section class="tabla-areas -section" v-if="areaseleccionada" id="tablasareas">

            <div class="container">
                <header class="encabezado-tabla-areas row-start">


                    <h3 class="contador -primary" @click="type = 'indicadores'" :active="type == 'indicadores'">
                        <span class="numero">{{ indicadores(areaseleccionada.id).length }}</span>
                        <span class="texto">Indicadores estratégicos, de acción y sectoriales</span>
                    </h3>

                    <h3 class="contador -primary" @click="type = 'actuaciones'" :active="type == 'actuaciones'">
                        <span class="numero">{{ areaseleccionada.actuaciones }}</span>
                        <span class="texto">Actuaciones</span>
                    </h3>

                    <h3 class="contador -primary" @click="type = 'cartas'" :active="type == 'cartas'">
                        <span class="numero">{{ cartas(areaseleccionada.id).length }}</span>
                        <span class="texto">Cartas de servicios</span>
                    </h3>
                </header>
                <resultados :type="'actuaciones'" :cansearch="true" :data="actuaciones(areaseleccionada.id)"
                    v-if="type == 'actuaciones'"></resultados>
                <resultados :type="'indicadores'" :cansearch="true" :data="indicadores(areaseleccionada.id)"
                    v-if="type == 'indicadores'"></resultados>

                <div class="cartas" v-if="type == 'cartas' && areaseleccionada">
                    <carta v-for="(data, key) in cartas(areaseleccionada.id)" :data="data" :key="key"></carta>
                </div>
            </div>

        </section>

        <banner-ayudamos :data="['areas-gobierno', 'competencias-areas', 'pleno']"></banner-ayudamos>

    </div>
</template>


<script>

import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

import resultados from '@/components/parts/resultados-busqueda'
import carta from '@/components/parts/carta-de-servicio';

import router from '@/router';

export default {
    name: 'areas-accion',
    mounted() {
        //this.setRandomArea();
        if (this.$route.params.id) {
            this.type = this.$route.params.id;
        }

        let index = 0;
        /** Si viene de la pagina anterior y tiene el parámetro areagobiernoid, entonces carga el area seleccionada */
        if (this.$route.params.areagobiernoid) {
            /** Busco el indice en this.dominios.areas_gobierno.id = areagobiernoid */
            index = this.dominios.areas_gobierno.findIndex(x => x.id == this.$route.params.areagobiernoid);
            this.areaseleccionada = this.dominios.areas_gobierno[index];
        } else {
            this.setRandomArea();
        }

        let typeaux = this.type;

        this.requestHomeData();
        this.requestDominios();

        setTimeout(() => {
            this.type = typeaux;
            this.areaseleccionada = this.dominios.areas_gobierno[index];
        }, 500);


    },
    data: () => ({
        areaseleccionada: '',
        type: 'indicadores'
    }),
    computed: {
        ...mapGetters({
            homedata: 'getHomedata',
            dominios: 'getDominios',
            actuaciones: 'getActuacionesByAreaMunicipal',
            indicadores: 'getIndicadoresByAreaMunicipal',
            cartas: 'getCartasByAreaMunicipal'
        }),
        noiniciadas() {
            return Number(this.areaseleccionada.actuacionesretrasada) + Number(this.areaseleccionada.actuacionespendienteplanificar) + Number(this.areaseleccionada.actuacionesfuturas)
        },
        terminadasyenejecucion() {
            let terminadas = Number(this.areaseleccionada.actuaciones) - Number(this.noiniciadas);
            let porcentaje = (terminadas * 100) / Number(this.areaseleccionada.actuaciones);

            return Number(porcentaje);
            //return Number(this.areaseleccionada.actuaciones) - Number(this.noiniciadas) 
        }
    },
    methods: {
        ...mapActions(['requestHomeData', 'requestDominios']),
        setRandomArea() {
            if (this.dominios.areas_gobierno) {
                this.areaseleccionada = this.dominios.areas_gobierno[0];
            }
        },
        back() {
            router.go(-1);
        }
    },
    components: {
        'resultados': resultados,
        'carta': carta,
    },
    watch: {
        'dominios.areas_gobierno'() {
            this.setRandomArea();
        },
        'type'() {
            let tablaareas = document.getElementById('tablasareas');
            scroll({
                top: tablaareas ? tablaareas.offsetTop - 200 : 0,
                behavior: "smooth"
            });

            router.replace({
                params: {
                    id: this.type
                }
            }).catch(() => { });
        },
        'areaseleccionada'() {
            this.show = false;

            if (this.areaseleccionada && this.areaseleccionada.id) {
                // Agrega a los parámetros de router `areagobiernoid`
                router.currentRoute.params.areagobiernoid = this.areaseleccionada.id;
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.contenedor-areas {
    .contenedor-input {
        max-width: 400px;
    }
}
</style>